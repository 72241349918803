.main_wrapper {
    height: 100vh;
    width: 100%;

    margin-top: -50px;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    background-image: url("../../Assets/Images/banner.jpg");
}

.main_wrapper h2 {
    margin-bottom: 20px;
}

.main_wrapper .form {
    display: flex;

    width: 20%;
    height: 350px;
    gap: 20px;
    
    align-items: center;
    flex-direction: column;
}

.main_wrapper .form input {
    min-width: 350px;
    height: 45px;
    border-radius: 5px;
    outline: none;
    border: none;
    box-shadow: 1px 2px 3px black;
    padding-left: 10px;
}

.main_wrapper .form button {
    min-width: 200px;

    height: 45px;
    border-radius: 5px;
    border: none;
    box-shadow: 1px 2px 3px black;
    transition: 0.5s all;

}
.main_wrapper .form button:hover {
   color: white;
   background-color: black;
}

@media (max-width:415px){
    .main_wrapper .form {
        padding: 0 10px;
        width: 100%;
        
    }
    .main_wrapper .form input {
       min-width: 100%;
    }
    
}