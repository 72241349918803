@import url('https://fonts.googleapis.com/css2?family=Open+Sans&family=Roboto&display=swap');


p {
    margin-top: 0;
    margin-bottom: 0rem !important;
}

.nav {
    background-color: red;
    height: 50px;
}

label{
    color:  #ec008c;
    font-size: 0.8rem;
}
.banner {
    width: 100%;

    position: fixed;
    z-index: -1;
    top: -50px;
    left: 0;

/* background: linear-gradient(90deg, rgba(2,0,36,1) 0%, rgba(236,166,234,1) 0%, rgba(0,212,255,1) 100%); */
}

.banner img {
    width: 100%;
    height: 100vh;
}


.membership_wrapper {

    height: 89vh;

    display: grid;
    align-items: center;
    grid-template-columns: 3fr 3fr;
    background-color: transparent;
}

.membership_wrapper .left {
    /* background-color: red; */
    /* border: 1px solid; */
    height: 50%;
}

.membership_wrapper .right {
    display: flex;
    /* height: 80%; */
   
    width: 70%;
    justify-content: flex-end;

    /* border: 1px solid; */


}

.membership_wrapper .right button {
    width: 150px;
    height: 45px;
    background: #fb660d;

    color: white;
    border: none;
    cursor: pointer;
    border-radius: 5px;
    margin: 15px;
}

.membership_wrapper .right .step1 {
    /* display: none; */
    margin-right: 20px;
}

.membership_wrapper .right .form {
    width: 85%;
    box-shadow: 0 10px 20px -12px rgb(0 0 0 / 42%), 0 3px 20px 0 rgb(0 0 0 / 12%), 0 8px 10px -5px rgb(0 0 0 / 20%);
    padding: 10px 50px;
    border-radius: 10px;
    background-color: white;
}

.membership_wrapper .right .form h1 {
    text-align: center;
    font-size: 1.7rem;
    margin-bottom: 25px;
    font-weight: 800;
    color: #161847;
    text-transform: uppercase;
    font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
}

.membership_wrapper .right .form .steps {
    margin: 12px 0;
    display: flex;
    align-items: center;
    justify-content: space-around;
    border-bottom: 1px dashed #ec008c;
    ;
    padding-bottom: 20px;
}

.membership_wrapper .right .form .steps p {
    display: flex;
    font-weight: 800;
    color: #ec008c;
    font-size: .8rem;
    align-items: center;
    font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
    gap: 10px;
}

.membership_wrapper .right .form .steps .count {
    display: flex;
    font-size: .6rem;
    align-items: center;
    justify-content: center;
    height: 15px;
    width: 15px;
    border: 1px solid;
    color: #ec008c;
    border-radius: 50%;
}

.membership_wrapper .right .form .personal_details h2 {
    font-size: 1.4rem;
    /* text-transform: uppercase; */
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
    color: #161847;
}

.membership_wrapper .right .form .personal_details .name_title {

    display: flex;
    align-items: center;
    gap: 25px;
    width: 100%;
    padding: 10px 0;
}

.membership_wrapper .right .form .personal_details .name_title label {
    color: #ec008c;
    width: fit-content;
    font-family: 'Open Sans', sans-serif;


}

.membership_wrapper .right .form .personal_details .name_title .title select {
    height: 37px;
    width: 70px;
}

.membership_wrapper .right .form .personal_details .name_title .name input {
    height: 31px;
    width: 70%;
    color: #ec008c;
    font-family: 'Open Sans', sans-serif;

}

.membership_wrapper .right .form .personal_details .name_title .name {
    width: 100%;
}

.membership_wrapper .right .form .personal_details .name_title .title,
.membership_wrapper .right .form .personal_details .name_title .name {
    display: flex;
    flex-direction: column;
    font-size: .8rem;
}


.membership_wrapper .right .form .personal_details .mobile_no .mobile_wrapper {
    display: flex;
    align-items: center;
    gap: 15px;
}

.membership_wrapper .right .form .personal_details .mobile_no label {
    display: block;
    margin-bottom: -12px;
    color: #ec008c;
    font-size: .8rem;
    width: fit-content;
    font-family: 'Open Sans', sans-serif;


}









.membership_wrapper .right .form .personal_details .mobile_no .mobile_wrapper input {
    padding-left: 5px;
    outline: none;
    width: 60%;
    height: 37px;
}


/* Hiding the arrow icon in input type number */

.membership_wrapper .right .form .personal_details .mobile_no .mobile_wrapper input[type="number"]::-webkit-inner-spin-button, 
.membership_wrapper .right .form .personal_details .mobile_no .mobile_wrapper input[type="number"]::-webkit-outer-spin-button { 
    -webkit-appearance: none; 
    margin: 0; 
}









.membership_wrapper .right .form .personal_details .email label {
    display: block;
    font-size: .8rem;

    color: #ec008c;
    font-family: 'Open Sans', sans-serif;

}

.membership_wrapper .right .form .personal_details .email {
    padding: 15px 0;
    display: flex;
    flex-direction: column;
}

.membership_wrapper .right .form .personal_details .email input {
    width: 70%;
    height: 37px;
    padding-left: 10px;
}

.membership_wrapper .right .form .personal_details .checkbox {
    display: flex;
    gap: 10px;
    margin-top: 15px;
    align-items: flex-start;

}

.membership_wrapper .right .form .personal_details .checkbox span {
    display: inline-block;
    width: 80%;
    font-size: .8rem;
    font-family: 'Roboto', sans-serif;

}


.step2 {
    width: 70%;
    /* display: none; */
    height: fit-content;
    padding: 30px 30px;
    border-radius: 10px;
    background-color: white;
    box-shadow: 0 10px 20px -12px rgb(0 0 0 / 42%), 0 3px 20px 0 rgb(0 0 0 / 12%), 0 8px 10px -5px rgb(0 0 0 / 20%);
}

.step2 .citizen {
    display: flex;
    align-items: center;
    
    height: 30px;
    gap: 5px;
}

.step2 .gender {
    height: 30px;
    display: flex;
    align-items: center;
    gap: 5px;
}

.step2 .profile_pic {
   display: flex;
    padding: 10px 0;
    gap: 20px;
    align-items: center;
}


.step2 .profile_pic img {
  width: 50px;
  height: 50px;
  margin-top: 19px;
}
.step2 .dob {
    display: flex;
    padding: 10px 0;
    gap: 1px;
    flex-direction: column;
  /* height: 50px; */
}
.step2 .dob > input{
    width: 150px;
    padding: 5px;
}

.step2 .contact_details h2{
    margin: 20px 0 10px 0px;

    font-size: 1.4rem;
    text-transform: uppercase;

}


.step2 .contact_details .contact_form_wrapper .home_address{
    display: flex;
    flex-direction: column;
    outline: none;
}


.step2 .contact_details .contact_form_wrapper .state_and_district select{
    width: 150px;
}


.step2 .contact_details .contact_form_wrapper .state_and_district{
    display: flex;
    margin-top: 10px;
    align-items: center;
    gap: 20px;
}


.step2 .contact_details .contact_form_wrapper .state_and_district .district{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}
.step2 .contact_details .contact_form_wrapper .state_and_district .district input {
   width: 100%; 
}

.step2 .contact_details .contact_form_wrapper .conscent{
    margin-top: 10px;
     display: flex;
     gap: 10px;
     /* align-items: center; */
}

.step2 .contact_details .contact_form_wrapper .conscent p{
    font-size: .7rem;
    width: 70%;
}








.footer {
    background-color: red;
}








/* Media Querries */

@media (max-width:1132px){
    .membership_wrapper .left {
        display: none;
    }
    .membership_wrapper {
        height: 90vh;
        grid-template-columns: 1fr;
        align-content: center;
        justify-items: center;
    
    }
    .step2 {
        width: 80%;
      
    }
}


@media (max-width:1009px){

    .membership_wrapper .right .form{
        width: 50%;
       
    }
    
    .membership_wrapper .right {
        width: 100%;
       
        justify-content: center !important;
  
    }

    .step2 {
        width: 70%;
      
    }
}

@media (max-width:812px){
    .membership_wrapper .right .form{
        width: 70%;
       
    }
    .banner img {
        display: none;
    }

}
@media (max-width:677px){
    .step2 {
        width: 80%;
    }

}
@media (max-width:562px){
    .membership_wrapper .right .form{
        width: 85%;
       
    }

}
@media (max-width:466px){
    .step2 {
        width: 93%;
    }
    .membership_wrapper .right .form{
        align-content: center;
       
        width: 95%;
    }
    
    .membership_wrapper .right {
       padding-left: 12px;
     
       
    }
    .step2 .contact_details .contact_form_wrapper .state_and_district{
        display: flex;
       flex-direction: column;
    }
    
    .step2 .contact_details .contact_form_wrapper .state_and_district  div{
       width: 100%;
       /* border: 1px solid; */
    }
    .step2 .contact_details .contact_form_wrapper .state_and_district div input, .step2 .contact_details .contact_form_wrapper .state_and_district div select{
       width: 100%;
       border: 1px solid;
    }
    
    .step2 .contact_details .contact_form_wrapper .conscent p{
        font-size: .8rem;
        width: 100%;
    }
    



    .membership_wrapper .right .form .personal_details .name_title {

        display: flex;
      align-items: flex-start;
       flex-direction: column;
       gap: 0;
    }

.membership_wrapper .right .form .personal_details .name_title .name input {
    width: 100%;
   
}

.membership_wrapper .right .form .personal_details .mobile_no .mobile_wrapper {
    display: flex;
    margin-top: 20px;
    align-items: flex-start;
    flex-direction: column;
    gap: 0px;
}



.membership_wrapper .right .form .personal_details .mobile_no .mobile_wrapper{
    /* border: 1px solid ; */
    width: 100%;
}
.membership_wrapper .right .form .personal_details .mobile_no .mobile_wrapper PhoneInput {
  /* border: 1px solid red; */
    width: 100%;
    height: 37px;
}

.membership_wrapper .right button {
    margin-left: 0;
}
.right{
    display: block;
}
.personal_details{
    width: 100%;

}


.membership_wrapper .right .form {
    
    padding: 10px 20px;
}



}



@media (max-width:380px){
    .step2 .profile_pic img {
        width: 35px;
        height: 35px;
    
      }
}
@media (max-width:323px){
    .membership_wrapper .right .form h1 {
        font-size: 1.3rem;
    }
}


/* font-family: 'Open Sans', sans-serif; */
/* font-family: 'Roboto', sans-serif; */