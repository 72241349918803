li{
    list-style: none;
}
.link{

    text-decoration: none;
    font-family: Roboto,sans-serif;
    font-size: 1rem;
    color: #000;
}

