.main_wrapper{
    display: flex;
    align-items: center;
    justify-content: center;
    background-image: url("../../Assets/Images/bg4.jpg");
    background-position: center;
    background-repeat: no-repeat;
    height: 100vh;
}

.form_wrapper label{
    position: relative;
    top: 10px;
}
.main_wrapper .form_wrapper{
    display: flex;
    flex-direction: column  ;
    align-items: center;
    justify-content: center;
    padding: 25px 20px;
    border-radius: 10px;
    background-color: white;
    box-shadow: 0 10px 20px -12px rgb(0 0 0 / 42%), 0 3px 20px 0 rgb(0 0 0 / 12%), 0 8px 10px -5px rgb(0 0 0 / 20%);
}

.main_wrapper .form_wrapper .phone_content{
    display: flex;
}


.main_wrapper .form_wrapper  button{
    width: 120px;
    height: 45px;
    background: #fb660d;
    color: white;
    border: none;
    cursor: pointer;
    border-radius: 5px;
    margin: 15px;
}
.main_wrapper .form_wrapper  input{
    height: 45px;
   padding-left: 10px;
   outline: none;
}


@media (max-width:812px){
    .main_wrapper{
       background: none;
    }
}
@media (max-width:430px){
    .main_wrapper .form_wrapper .phone_content{
        display: flex;
        margin: 15px 0;
        /* border: 1px solid; */
        flex-direction: column;
    }
    .main_wrapper .form_wrapper{
        width: 100%;
        /* border: 1px solid; */
      
        display: block;
    }
    .main_wrapper .form_wrapper  button{
        margin-left: 36px;
    }
    .main_wrapper{
        display: block;
       
        height: 100vh;
    }
}
@media (max-width:406px){
    .otp_wrapper .otp_content{
        /* border: 1px solid; */
        margin-top: 160px;
    }
    .main_wrapper .form_wrapper{
        margin-top: 60px;
    }
    .otp_wrapper .otp_content input{
      width: 100%;
    }
    .otp_wrapper .otp_content button{
      margin-left: 0;
    }
}