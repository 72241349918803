.main{

    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    height:80vh;
}
.main .card_wrapper .header .id{
display: flex;
gap: 2px;
flex-direction: column;
justify-content: center;
}
.download_btn button{
    width: 150px;
    height: 45px;
    background: #fb660d;
    color: white;
    border: none;
    cursor: pointer;
    border-radius: 5px;
    margin: 15px;
}
.card_wrapper {
      /* width: 40%; */
      min-height: 347px;

      min-width: 430px !important;
      margin: 50px auto;
    /* border: 1px solid; */
      display: flex;
      align-items: center;
      flex-direction: column;
      justify-content: space-around;
      background-image: url('../../Assets/Images/frame.jpg');
      background-repeat: no-repeat;
      background-position: center;
      background-size: 111%;
      
      position: relative;
}

.card_wrapper .profile_pattern {
    width: 150px;
    position: absolute;
    bottom: 20px;
    left: 40px;
}

.card_wrapper .header {

        /* width: 97%; */
        min-width: 628px;
    /* border: 1px solid; */
    margin-top: -28px;
    display: flex;
    /* padding: 0 14px; */
    padding: 9px 14px;
    justify-content: space-between;
}
.card_wrapper .header img{
    height: 60px;
   
}

.card_wrapper .header .id p{
    color: white;
    font-size: .8rem;
    font-weight: 600;
}
.card_wrapper .header .id p img{
   margin-right: 10px;
}

.card_wrapper .profile_content {
    display: grid;
    padding: 0 25px;
    align-content: center;
    grid-template-columns: repeat(2,1fr);
    /* gap: 20px; */
    margin-left: -30px;
    align-items: center;
    width: 90%;
    min-width: 350px;
    max-width: 663px;
}
    /* border: 1px solid; */

.card_wrapper .profile_content .profile{
    position: relative;
    
}
.card_wrapper .profile_content .profile .pattern{
    position: absolute;
    width: 142px;
    z-index: 1;
    bottom: -3%;
    left: -1px;
}
.card_wrapper .profile_content .profile .member_image {
    width: 140px;
    height: 140px;
    z-index: 5;
    border-radius: 50%;
}
.card_wrapper .profile_content .content{
    min-width: 334px;
    /* border: 1px solid; */
}
.card_wrapper .profile_content .content p{
    color: #88111c;
    font-weight: 700;
        padding-left: 24px;
}


